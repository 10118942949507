<template>
    <q-modal-view
        id="modPrimeiraSenha"
        ref="modal"
        :title="tituloModal"
        disableDismiss
        blockDismiss
        loading
    >
        <div
            v-if="loading"
            class="fixed-center"
        >
            <q-circular-progress
                indeterminate
                size="50px"
                color="primary"
                class="q-ma-md"
            />
        </div>
        <div
            v-else
            class="q-pa-md"
        >
            <div class="col">
                <p class="text-center" v-html="descricao"></p>
            </div>
            <div class="col-12 q-pa-xs">
                <q-input
                    ref="codigoAcesso"
                    v-model="codigoAcesso"
                    label="Chave de acesso *"
                    hint="Digite o código que recebeu por SMS"
                    :rules="[
                        val => !!val || 'É necessário preencher este campo'
                    ]"
                    lazy-rules
                    square
                    filled
                />
            </div>
            <div class="col-12 q-pt-md">
                <q-btn
                    class="full-width"
                    color="grey-7"
                    :label="`${submitting ? mensagemAguardeTempo : 'Reenviar código de acesso'}`"
                    @click="$_reenviarCodigoAcesso"
                    :disabled="submitting"
                />
            </div>
            <div class="col-12 q-pt-md">
                <q-input
                    ref="senha"
                    v-model="senha"
                    maxlength="4"
                    label="Senha *"
                    hint="A senha deve conter somente 4 números"
                    :type="isPwdSenha ? 'password' : 'text'"
                    inputmode="numeric"
                    :rules="[
                        val => !!val || 'É necessário preencher este campo',
                        val => val.length <= 4 || 'A senha deve possuir 4 dígitos numéricos'
                    ]"
                    lazy-rules
                    square
                    filled
                    @keyup="() => { senha = senha.replace(/[^0-9]+/g, '') }"
                >
                    <template v-slot:append>
                        <q-icon
                            :name="isPwdSenha ? 'visibility_off' : 'visibility'"
                            class="cursor-pointer"
                            @click="isPwdSenha = !isPwdSenha"
                        />
                    </template>
                </q-input>
            </div>
            <div class="col-12 q-pt-md">
                <q-input
                    ref="confirmacaoSenha"
                    v-model="confirmaSenha"
                    maxlength="4"
                    label="Confirme a senha *"
                    hint="Repita a senha digitada anteriormente"
                    :type="isPwdConfSenha ? 'password' : 'text'"
                    inputmode="numeric"
                    :rules="[
                        val => !!val || 'É necessário preencher este campo',
                        val => val === senha || 'As senhas não coincidem'
                    ]"
                    lazy-rules
                    square
                    filled
                    @keyup="() => { confirmaSenha = confirmaSenha.replace(/[^0-9]+/g, '') }"
                >
                    <template v-slot:append>
                        <q-icon
                            :name="isPwdConfSenha ? 'visibility_off' : 'visibility'"
                            class="cursor-pointer"
                            @click="isPwdConfSenha = !isPwdConfSenha"
                        />
                    </template>
                </q-input>
            </div>
            <div class="col-12 q-pt-md">
                <q-btn
                    class="full-width"
                    color="positive"
                    label="Criar Senha"
                    @click="$_criarSenha"
                />
            </div>
        </div>
    </q-modal-view>
</template>

<script>
import QModalView from '@/components/shared/QModalView'
import MixinMessages from '@/mixins/mixinMessages'
import ParametrosService from '@/services/parametros'
import CartaoService from '@/services/cartao'
import store from '@/store/index'

export default {
    name: 'QModalPrimeiraSenha',

    components: {
        QModalView,
    },

    data() {
        return {
            descricao: null,
            codigoAcesso: '',
            senha: '',
            confirmaSenha: '',
            isPwdConfSenha: true,
            isPwdSenha: true,
            enviando: false,
            mensagemAguardeTempo: 'Enviando SMS ...',
            loading: true
        }
    },

    mixins: [
        MixinMessages
    ],

    computed: {
        tituloModal() {
            return `Senha Cartão ${process.env.VUE_APP_DESCRICAO_EMPRESA}`
        },

        validaCampos() {
            this.$refs.codigoAcesso.validate()
            this.$refs.senha.validate()
            this.$refs.confirmacaoSenha.validate()

            const errors = [
                !this.$refs.codigoAcesso.hasError,
                !this.$refs.senha.hasError,
                !this.$refs.confirmacaoSenha.hasError,
            ]

            if (errors.includes(false) || !this.senhaValida) {
                return false
            }
            return true
        },

        senhaValida() {
            return this.senha === this.confirmaSenha
        }
    },

    methods: {
        open() {
            this.$_buscaDescricao()
            this.$refs.modal.show()
        },

        close() {
            this.$refs.modal.hide()
        },

        $_buscaDescricao() {
            this.loading = true
            ParametrosService.getParametro('CLIENTE.MENSAGEM_PRIMEIRA_SENHA')
                .then(({ data }) => {
                    const values = {
                        '{NOME_CLIENTE}': store.getters.getUsuarioLogado.nome,
                        '{NOME_EMPRESA}': process.env.VUE_APP_DESCRICAO_EMPRESA,
                    }
                    this.descricao = String(data[0].valor).replace(
                        /{NOME_CLIENTE}|{NOME_EMPRESA}/gi,
                        (match) => values[match]
                    )
                    this.loading = false
                })
                .catch(e => this.errorMessage({
                    title: 'Ops, algo de errado aconteceu!',
                    message: 'Erro ao buscar um parametro',
                    error: e
                }))
        },

        $_criarSenha() {
            if (this.validaCampos) {
                this.showLoading({ message: `Salvando sua a senha do Cartão ${process.env.VUE_APP_DESCRICAO_EMPRESA}, Aguarde!` })
                CartaoService.putCriarSenha(store.getters.getUsuarioLogado.id_cartao, this.codigoAcesso, this.senha)
                    .then(() => {
                        const primeiroAcesso = store.getters.getUsuarioLogado.criar_senha
                        this.successMessage({
                            title: 'Senha criada com sucesso!',
                            message: `A senha foi criada com sucesso, agora você pode efetuar suas comprar com seu Cartão ${process.env.VUE_APP_DESCRICAO_EMPRESA}!`
                        })
                        store.commit('login', { ...store.getters.getUsuarioLogado, criar_senha: false })
                        this.close()
                        if (primeiroAcesso) {
                            this.$router.push({
                                name: "Cartão Virtual",
                                query: { primeiro_acesso: primeiroAcesso }
                            });
                        }
                    })
                    .catch(e => this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao criar a senha',
                        error: e
                    }))
                    .finally(() => {
                        this.hideLoading()
                    })
            }
        },

        $_reenviarCodigoAcesso(){
            this.submitting = true
            CartaoService.postReenvioCodigoAcesso(store.getters.getUsuarioLogado.id_cartao)
                .then(() => {
                    this.successMessage({
                        title: 'SMS enviado',
                        message: 'Você receberá em breve o código de acesso por SMS!'
                    })
                    this.$_iniciarContadorSms()
                })
                .catch(e => {
                    this.submitting = false
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Não conseguimos reenviar o código de acesso para você, tente novamente mais tarde!',
                        error: e
                    })
                }
            )
        },

        $_iniciarContadorSms() {
            let minutos = process.env.VUE_APP_TEMPO_SMS
            let segundos = 59
            let tempoEsperaSms = ''
            minutos--
            const analiseTimer = setInterval(() => {
                segundos--
                if (segundos < 0) {
                    minutos--
                    segundos = 59
                    if (minutos < 0){
                        clearInterval(analiseTimer)
                        this.submitting = false
                    }
                }
                tempoEsperaSms = `${minutos}:${segundos < 10 ? `0${segundos}` : segundos}`
                this.mensagemAguardeTempo = `Você poderá reenviar o sms em: ${tempoEsperaSms}`
            }, 1000)
        }
    }
}
</script>

<style>

</style>